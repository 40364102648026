import { useState, useEffect, useRef } from 'react';
import './PanthersPack.scss';
import { useSpring, a } from 'react-spring';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import {ReactComponent as Play} from '../../Assets/Icons/play-button.svg';
import {ReactComponent as Camera} from '../../Assets/Icons/camera.svg';
import GaleryModal from '../../Components/UI/GalleryModal/GalleryModal';
import {format} from 'date-fns';
import QuizBox from '../../Components/QuizBox/QuizBox';
import PageTransition from '../../Components/PageTransition/PageTransition';
import CONSTANTS from '../../Constants';
import ImageGallery from './ImageGallery/ImageGallery';
import { useHistory } from 'react-router';
import { useQuery, gql } from '@apollo/client';

const PANTHERS_PACK_QUERY = gql`
query PanthersPackQuery($id: ID){
    panthersPack{
        heroMedia{
            url,
            alternativeText
        }
    }
    quizzes(where:{isActive:true}, sort:"endTime:desc", limit: 1){
        id,
        title
        endTime,
        startTime,
        tallImage{
            url,
            alternativeText
        },
        winner{
            id
        },
        wideImage{
            url,
            alternativeText
        }
        question,
        quizOption{
            id,
            text,
        }
        quiz_responses(where:{user:{id: $id}}, limit: 1){
            id,
            text,
            user{
                id
            }
        }
    }
}`;

const IMAGE_GALLERY_QUERY = gql`
    query ImageGalleryQuery($offset: Int, $limit: Int){
        imageGalleries(sort: "created_at:desc", start: $offset, limit: $limit){
            id,
            title,
            text,
            mainImage{
                url,
                alternativeText
            }
        },
    }
`;

const PANTHER_PACK_VIDEOS_QUERY = gql`
    query PantherpackVideoQuery($offset: Int, $limit: Int){
        pantherPackVideos(sort: "created_at:desc", start: $offset, limit: $limit){
            id,
            title,
            text,
            videoUrl
        }
    }
`;




export default function PantherPack({loggedIn, user, setOpen, setProgress, isSafari}){
    const [tabs, setTabs] = useState(null);

    const {data, error, loading} = useQuery(PANTHERS_PACK_QUERY, {
        variables: {
            id: user? parseInt(user.id) : null
        }
    });

    const {data: imageGalleryData, previousData: imagePreviousData, loading: imagesLoading, fetchMore: fetchMoreImages } = useQuery(IMAGE_GALLERY_QUERY, {
        variables: {
            offset: 0,
            limit: 9
        },
        notifyOnNetworkStatusChange: true
    });

    const {data: videoGalleryData, previousData: videoPreviousData, loading: videosLoading, fetchMore: fetchMoreVideos } = useQuery(PANTHER_PACK_VIDEOS_QUERY, {
        variables: {
            offset: 0,
            limit: 9
        },
        notifyOnNetworkStatusChange: true
    });


    const history = useHistory();
    const imageOffset = useRef(0);
    const videoOffset = useRef(0);

    useEffect(() => {
        if(imageGalleryData && videoGalleryData){
            const tabs = [];
            if(imageGalleryData.imageGalleries.length) tabs.push({name: 'IMAGE GALLERY'});
            if(videoGalleryData.pantherPackVideos.length) tabs.push({name: 'VIDEO GALLERY'});
            setTabs(tabs);
        }
    }, [imageGalleryData, videoGalleryData]);

    const fetchNewImages = () => {
        if(imagePreviousData && (imagePreviousData.length === imageGalleryData.length)){
        } else {
            fetchMoreImages({
                variables: {
                    offset: imageOffset.current + 9,
                    limit: 9,
                }
            });
            imageOffset.current += 9;
        }
    }

    const fetchNewVideos = () => {
        fetchMoreVideos({
            variables: {
                offset: videoOffset.current + 9,
                limit: 9,
            }
        });
        videoOffset.current += 9;
    }

    if(data && tabs){
        return(
            <Switch>
                <Route path="/panther-pack" exact>
                    <PanthersPackMain 
                        tabs={tabs} 
                        data={data} 
                        imageGalleries={imageGalleryData}
                        imagesloading={imagesLoading}
                        fetchMoreImages={fetchNewImages}
                        videoGalleries={videoGalleryData}
                        videosloading={videosLoading}
                        fetchMoreVideos={fetchNewVideos}
                        user={user} 
                        loggedIn={loggedIn} 
                        setOpen={setOpen} 
                        isSafari={isSafari}
                        setProgress={setProgress}
                    />
                </Route>
                <Route path="/panther-pack/image-gallery/:id">
                    <ImageGallery 
                        loggedIn={loggedIn} 
                        user={user} 
                        type={'image'} 
                        setProgress={setProgress}
                        isSafari={isSafari}
                    />
                </Route>
                {/* <Route path="/panther-pack/video-gallery/:id">
                    <ImageGallery loggedIn={loggedIn} user={user} type={'video'}/>
                </Route> */}
                <Redirect to="/panther-pack" />
            </Switch>
        )
    }else if(error){
        history.push("/home");
        return <div />
    }else return <PageTransition />
}

function PanthersPackMain({tabs, data, user, loggedIn, setOpen, setProgress, imageGalleries, videoGalleries, ...props}){
    const [loaded, setLoaded] = useState(false);
    const [screenWidth, setScreenWidth] = useState(0);
    const [activeTab, setActiveTab] = useState(null);
    const [tabItem, setTabItem] = useState(null);
    const [modalOpen, setModalOpen] = useState(false); //video gallery modal open state
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(0); // selected video index
    const tabContainer = useRef();

    const history = useHistory();

    const parent = useRef();
    const child = useRef();
    const overlay = useRef();
    const gallery = useRef();

    useEffect(() => {
        setTimeout(() => setLoaded(true), 100);
        let position = 0;
        let ticking = false;
        const scrollRange = parent.current.clientHeight - child.current.clientHeight;

        const scrollTopHandler = (position) => {
            if(position < scrollRange*0.8){
                overlay.current.style['transform'] = `scale(${1 + position*position*50/(scrollRange*scrollRange)})`;
                overlay.current.style['opacity'] = '1';
            } else if(position < scrollRange){
                overlay.current.style['transform'] = `scale(${1 + position*position*50/(scrollRange*scrollRange)})`;
                overlay.current.style['opacity'] = (scrollRange - position)/(scrollRange*0.2).toFixed(2);

            }else{
                overlay.current.style['opacity'] = '0';
            }
        }

        // Throttled callback for scroll event listener
        const callback = (event) => {
            position = window.scrollY;

            if (!ticking && overlay.current) {
                window.requestAnimationFrame(function() {
                    scrollTopHandler(position);
                    ticking = false;
                });

                ticking = true;
            }
        } 

        document.addEventListener('scroll', callback);

        return(() => window.removeEventListener('scroll', callback));
    },[]);

    useEffect(() => {
        document.fonts.ready.then(() => {
            setActiveTab(0);
            setProgress(prog => prog + 40);
        });
        const setSize = (event) => {
            setScreenWidth(event.target.innerWidth);
        }
        setScreenWidth(window.innerWidth);
        window.addEventListener('resize', setSize);
        return () => {
            window.removeEventListener('resize', setSize);
        }
    }, []);

    useEffect(() => {
        setTabItem(tabContainer.current.children[activeTab]);
    }, [activeTab]);

    useEffect(() => {
        let position = 0;
        let ticking = false;
        
        const scrollHandler = (position) => {
            if(gallery.current){
                const galleryDim = gallery.current.getBoundingClientRect();
                if((galleryDim.top + galleryDim.height) < (window.innerHeight * 1.5)){
                    if(tabs[activeTab].name==='IMAGE GALLERY'){
                        if(props.imagesLoading){

                        }else {
                            props.fetchMoreImages();
                        }
                    } else {
                        if(props.videosLoading){
                            
                        }else {
                            props.fetchMoreVideos();
                        }
                    }
                }
            }
        }

        const callback = (event) => {
            position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    scrollHandler(position);
                    setTimeout(() => {
                        ticking = false;
                    }, 100)
                });

                ticking = true;
            }
        } 

        document.removeEventListener('scroll', callback);

        if(gallery.current){
            document.addEventListener('scroll', callback);

        }
        return(() => document.removeEventListener('scroll', callback));
    }, [activeTab]);

    const ReturnSpring = (item, screenWidth) => {
        return useSpring({left: item? item.offsetLeft : 0, width: item? item.offsetWidth : 1});
    }
    
    const tabHighlightSpring = ReturnSpring(tabItem, screenWidth);

    const detectUrlType = (string) => {
        const images = ["jpg", "gif", "png"]
        const videos = ["mp4", "3gp", "ogg"]

        const url = new URL(CONSTANTS.createUrl(string));
        const extension = url.pathname.split(".").slice(-1)[0];

        if (images.includes(extension)) {
            return 'image';
        } else if (videos.includes(extension)) {
            return 'video';
        }
    }
    return(
        <div className="panthers-pack">
            <div className="section-1" ref={parent}>
                <div className="text-keyhole" ref={child}>
                    {loaded? <>    
                            {data ? 
                                (
                                    (detectUrlType(data.panthersPack.heroMedia.url) === 'image')?
                                        <img 
                                            src={CONSTANTS.createUrl(data.panthersPack.heroMedia.url)}
                                            alt={data.panthersPack.heroMedia.alternativeText}
                                            onLoad={() => {
                                                setProgress(prog => prog + 20)
                                            }}
                                        />
                                        : <video 
                                            src={CONSTANTS.createUrl(data.panthersPack.heroMedia.url)} 
                                            autoPlay={true}
                                            preload='auto'
                                            muted
                                            onCanPlayThrough={() => {
                                                setProgress(prog => prog + 20)
                                            }}
                                            playsInline
                                            loop={true}
                                        />
                                )
                                : null
                            }
                            <div className="svg-wrapper" ref={overlay}>
                                <svg >
                                    <mask id="mask">
                                        <rect x="0%" y="0%" width="100%" height="100%" fill="white" />
                                        <text id="text" x="50%" y="50%" fill="black">#PANTHERSPACK</text>
                                    </mask>
                                    <rect x="0%" y="0" width="100%" height="100%" id="rect" fill="white" mask="url(#mask)"/>
                                </svg>
                            </div>
                        </>: null
                    }
                </div>
            </div>
            {
                (data && data.quizzes.length)?
                <div className="section-2">
                    <div className="main">
                        <QuizBox data={data.quizzes[0]} user={user} loggedIn={loggedIn} setOpen={setOpen} isSafari={props.isSafari}/>
                        <img className="desktop-image" src={CONSTANTS.createUrl(data.quizzes[0].tallImage.url)} alt={data.quizzes[0].wideImage.alternativeText} />
                        <img className="mobile-image" src={CONSTANTS.createUrl(data.quizzes[0].wideImage.url)} alt={data.quizzes[0].tallImage.alternativeText} />
                    </div>
                </div> : null
            }
            {tabs.length && <div className="section-3">
                <div className="title">
                    <h1>EXCLUSIVE CONTENT</h1>
                    <div className="tabs" >
                        <div className="tab-row" ref={tabContainer}>
                            {tabs.map((item, index) => 
                                <p 
                                    className={`item${(activeTab === index)? ' active' : ''}`}
                                    onClick={() => setActiveTab(index)}
                                >{item.name}</p>
                            )}
                        </div>
                        <div className="highlight-row">
                            <a.div className="highlighter" style={{...tabHighlightSpring}}/>
                        </div>
                    </div>
                </div>
                {(tabs && (activeTab!==null))?<div className="main">
                    <div className="tab" ref={gallery}>
                        {(tabs[activeTab].name==='IMAGE GALLERY')?
                            imageGalleries&&imageGalleries.imageGalleries.map(item => 
                                <Link 
                                    to={`/panther-pack/image-gallery/${item.id}`}
                                    onClick={(e) => {
                                        if(!(user && loggedIn)){
                                            e.preventDefault();
                                            if(props.isSafari){
                                                history.push("/login");
                                            }else{
                                                setOpen(true);
                                            }
                                        }
                                    }} 
                                    className="item"
                                >
                                    <img src={CONSTANTS.createUrl(item.mainImage.url)} alt={item.mainImage.alternativeText} />
                                    <p className="title">{item.title}</p>
                                    <p className="text">{item.text}</p>
                                    <div className="buttons">
                                        <button>VIEW GALLERY</button>
                                        <Camera />
                                    </div>
                                </Link>    
                            )
                            : videoGalleries&&videoGalleries.pantherPackVideos.map((item, index) => 
                                <button 
                                    className="item"
                                    onClick={(e) => {
                                        if(!(user && loggedIn)){
                                            if(props.isSafari){
                                                history.push("/login");
                                            }else{
                                                setOpen(true);
                                            }
                                        } else {
                                            setSelectedVideoIndex(index)
                                            setModalOpen(true);
                                        }
                                    }}
                                >
                                    <img src={`https://img.youtube.com/vi/${item.videoUrl.substring(item.videoUrl.indexOf('embed/') + 6)}/0.jpg`} alt=""/>
                                    <p className="title">{item.title}</p>
                                    <p className="text">{item.text}</p>
                                    <div className="buttons">
                                        <button>VIEW GALLERY</button>
                                        <Play />
                                    </div>
                                </button>
                            )
                        }
                    </div>
                </div> : null}
            </div>}
            {(videoGalleries.pantherPackVideos.length)?
                <GaleryModal 
                    open={modalOpen} 
                    setOpen={setModalOpen} 
                    data={videoGalleries.pantherPackVideos} 
                    selectedIndex={selectedVideoIndex} 
                    setSelectedIndex={setSelectedVideoIndex}
                    type={'video'}
                />
                :null
            }
        </div>
    )
}