import { useState, useEffect, useRef } from "react";
import "./HomePage.scss";
import CONSTANTS from "../../Constants";
import Panther from "../../Assets/pink-panther-logo.png";
import Hub from "../../Assets/Icons/field.svg";
import Arrow from "../../Assets/Icons/arrow-right.svg";
import Highlights from "../../Assets/Icons/videocam.svg";
import Stats from "../../Assets/Icons/stats.svg";
import Shirt from "../../Assets/Icons/shirt.svg";
import Instagram from "../../Assets/Icons/instagram.svg";
import Facebook from "../../Assets/Icons/facebook.svg";
import Twitter from "../../Assets/Icons/twitter.svg";
import Podium from "../../Assets/Icons/podium.svg";
import Wins from "../../Assets/Icons/wins.svg";
import Losses from "../../Assets/Icons/losses.svg";
import Draws from "../../Assets/Icons/draws.svg";
import Points from "../../Assets/Icons/points.svg";
import Camera from "../../Assets/Icons/camera.svg";
import Play from "../../Assets/Icons/play-button.svg";
import { ReactComponent as Trophy } from "../../Assets/Icons/trophy.svg";
import HomeLandingCarousel from "../../Components/HomeLandingCarousel/HomeLandingCarousel";
import FixtureAndResults from "../../Components/FixturseAndResults/FixturesAndResults";
import HomePlayerImages from "../../Components/HomePlayerImages/HomePlayerImages";
import PageTransition from "../../Components/PageTransition/PageTransition";
import SponsorRow from "../../Components/SponsorRow/SponsorRow";
import GooglePlay from "../../Assets/Icons/google-play.svg";
import Apple from "../../Assets/Icons/apple.svg";
import LandingPhone from "../../Assets/landing-phone.png";
import { useSpring, a } from "react-spring";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { Link, useHistory } from "react-router-dom";

const HOME_LANDING_QUERY = gql`
  query HomeLandingQuery($time: DateTime) {
    homePage {
      showAppSection
      landingImageWide {
        url
        alternativeText
      }
      landingImageTall {
        url
        alternativeText
      }
      panthersPackMedia {
        url
        alternativeText
      }
      playStoreLink
      appStoreLink
    }
    lastMatch: matches(sort: "startTime:desc", where: { startTime_lt: $time }) {
      id
      tournament {
        name
      }
      startTime
      jppScore
      opponentScore
      streamUrl
      team {
        name
        icon {
          url
          alternativeText
        }
      }
    }
    merchPage {
      isActive
    }
  }
`;

const HOME_MATCHES_QUERY = gql`
  query HomeMatchesQuery {
    upcomingMatch: matches(
      where: { tournament: 10, status: "Upcoming" }
      sort: "startTime:desc"
      limit: 25
    ) {
      id
      tournament {
        name
      }
      startTime
      jppScore
      opponentScore
      streamUrl
      team {
        name
        icon {
          url
          alternativeText
        }
      }
      result
    }

    previousMatchesUpcoming: matches(
      where: { tournament: 13, status: "Upcoming" }
      sort: "startTime:asc"
      limit: 1
    ) {
      id
      tournament {
        name
      }
      startTime
      jppScore
      opponentScore
      streamUrl
      team {
        name
        icon {
          url
          alternativeText
        }
      }
      result
    }

    previousMatchesOver: matches(
      where: { tournament: 13, status: "Over" }
      sort: "tournament:desc,startTime:desc"
      limit: 25
    ) {
      id
      tournament {
        name
      }
      startTime
      jppScore
      opponentScore
      streamUrl
      team {
        name
        icon {
          url
          alternativeText
        }
      }
      result
    }
  }
`;

const HOME_PLAYERS_QUERY = gql`
  query HomePlayersQuery {
    players(where: { isActive: true }, sort: "displayOrder:asc") {
      id
      squadNumber
      firstName
      lastName
      category
      matchesPlayed
      totalRaidPoints
      totalTacklePoints
      superTens
      highFives
      halfImage {
        url
        alternativeText
      }
      instagram
      facebook
      twitter
      merchLink
      position
    }
  }
`;

const HOME_STANDINGS_QUERY = gql`
  query HomeStandingsQuery {
    tournaments(sort: "startDate:desc", limit: 1) {
      id
      winner {
        id
      }
      teamStandings {
        team {
          id
          name
          icon {
            url
            alternativeText
          }
          isPanther
        }
        position
        matches
        wins
        losses
        draws
        difference
        points
        hasQualified
      }
      subDivision {
        name
        teamStandings {
          team {
            id
            name
            icon {
              url
              alternativeText
            }
            isPanther
          }
          position
          points
          matches
          wins
          losses
          draws
          difference
          hasQualified
        }
      }
    }
  }
`;

const HOME_MERCH_QUERY = gql`
  query HomeMerchQuery {
    merchandises(sort: "created_at:desc") {
      url
      image {
        url
        alternativeText
      }
    }
  }
`;

const HOME_PANTHERPACK_QUERY = gql`
  query HomePantherPackQuery {
    imageGalleries(sort: "created_at:desc", limit: 5) {
      id
      created_at
      title
      mainImage {
        url
        alternativeText
      }
    }
    pantherPackVideos(sort: "created_at:desc", limit: 5) {
      id
      created_at
      title
      text
      videoUrl
    }
  }
`;

const HOME_NEWS_QUERY = gql`
  query HomeNewsQuery {
    newsItems(sort: "date:desc", where: { isWide: false }, limit: 3) {
      date
      id
      title
      image {
        url
        alternativeText
      }
      news_label {
        name
      }
      articleUrl
    }
    wideNews: newsItems(sort: "date:desc", where: { isWide: true }, limit: 1) {
      date
      id
      title
      image {
        url
        alternativeText
      }
      articleUrl
      text
      news_label {
        name
      }
    }
  }
`;

const HOME_SPONSORS_QUERY = gql`
  query HomeSponsorsQuery {
    sponsorTypes {
      name
      type
      sponsors {
        title
        id
        image {
          url
          alternativeText
        }
        isTitleSponsor
        url
      }
    }
  }
`;

// const HomeQuery = gql`
// query HomeQuery($time: DateTime){
//     homePage{
//         showAppSection,
//         landingImageWide{
//             url,
//             alternativeText
//         },
//         landingImageTall{
//             url,
//             alternativeText
//         },
//         panthersPackMedia{
//             url,
//             alternativeText
//         },
//         playStoreLink,
//         appStoreLink
//     },
//     lastMatch: matches(sort:"startTime:desc", where: {startTime_lt: $time}){
//         id
//         tournament{
//             name
//         },
//         startTime,
//         jppScore,
//         opponentScore,
//         streamUrl,
//         team{
//             name,
//             icon{
//                 url,
//                 alternativeText
//             }
//         }
//     }
//     matches(sort:"startTime:desc"){
//         id
//         tournament{
//             name
//         },
//         startTime,
//         jppScore,
//         opponentScore,
//         streamUrl,
//         team{
//             name,
//             icon{
//                 url,
//                 alternativeText
//             }
//         },
//         result
//     },
//     players(where: {isActive:true}, sort: "displayOrder:asc"){
//         id,
//         squadNumber,
//         firstName,
//         lastName,
//         category,
//         matchesPlayed,
//         totalRaidPoints,
//         totalTacklePoints,
//         superTens,
//         highFives,
//         halfImage{
//             url,
//             alternativeText
//         },
//         instagram,
//         facebook,
//         twitter,
//         merchLink,
//         position
//     },
//     tournaments(sort:"startDate:desc", where:{startDate_lt: $time}, limit:1){
//         id,
//         teamStandings{
//             team{
//                 name,
//                 icon{
//                     url,
//                     alternativeText,
//                 },
//                 isPanther
//             },
//             position,
//             matches,
//             wins,
//             losses,
//             draws,
//             difference,
//             points,
//             hasQualified,
//         },
//         subDivision{
//             name,
//             teamStandings{
//                 team{
//                     name,
//                     icon{
//                         url,
//                         alternativeText
//                     }
//                     isPanther
//                 },
//                 position,
//                 points,
//                 matches,
//                 wins,
//                 losses,
//                 draws,
//                 difference,
//                 hasQualified
//             }
//         }
//     },
//     merchandises(sort:"created_at:desc"){
//         url
//         image{
//             url,
//             alternativeText
//         }
//     },
//     newsItems(sort:"date:desc", where:{isWide:false}, limit: 3){
//         date,
//         id,
//         title,
//         image{
//             url,
//             alternativeText
//         },
//         news_label{
//             name
//         }
//     },
//     wideNews: newsItems(sort:"date:desc", where:{isWide:true}, limit: 1){
//         date,
//         id,
//         title,
//         image{
//             url,
//             alternativeText
//         },
//         text,
//         news_label{
//             name
//         }
//     },
//     imageGalleries(sort:"created_at:desc", limit: 5){
//         id
//         created_at,
//         title,
//         mainImage{
//             url,
//             alternativeText
//         }
//     },
//     pantherPackVideos(sort:"created_at:desc", limit: 5){
//         id
//         created_at,
//         title,
//     		text,
//     		videoUrl
//     }
//     sponsorTypes{
//     	name,
//     	type,
//     	sponsors{
//             title,
//             id,
//             image{
//                 url,
//                 alternativeText
//             },
//             isTitleSponsor
//       }
// 	},
//     merchPage{
//         isActive
//     }
// }`;



const singleUpcommingFixture = {
  "id": "196",
  "result": "TBD", // or "Completed" for past matches
  "startTime": "2024-10-20T14:30:00Z", // ISO 8601 date string
  "tournament": {
    "name": "Season 11"
  },
  "team": {
    "name": "Bengal Warriorz",
    "icon": {
      "url": "/uploads/BENGAL_WARRIORZ_Championship_Star_Logo_d73a08cdf1.png",
      "alternativeText": "Bengal Warriorz Icon"
    }
  },
  "streamUrl": "#" // Only relevant for past matches
}

export default function HomePage({
  setOpen,
  loggedIn,
  user,
  setProgress,
  now,
  isSafari,
}) {
  // const now = useRef(new Date());
  const filterChangeTimer = useRef(null);
  const [screenWidth, setScreenWidth] = useState(0);
  const [squadFilter, setSquadFilter] = useState(null);
  const [squadPlayerPos, setSquadPlayerPos] = useState(null);
  const [squadImagePos, setSquadImagePos] = useState(0);
  const [squadArray, setSquadArray] = useState([]);
  const [standings, setStandings] = useState([]);
  const [mediaGalleries, setMediaGalleries] = useState([]);
  const squadInner = useRef(null);
  const history = useHistory();

  //Test Comment
  console.log('Test Comment Firebase Deploy!')

  const [pantherPackScroll, setPantherPackScroll] = useState(0);
  const pantherPackRef = useRef(null);

  const pantherPackStyles = useSpring({ scroll: pantherPackScroll });

  const [shoppingScroll, setShoppingScroll] = useState(0);
  const shoppingRef = useRef(null);

  const shoppingStyles = useSpring({ scroll: shoppingScroll });

  const [fixtureScroll, setFixtureScroll] = useState(0);
  const fixtureRef = useRef(null);

  const fixtureStyles = useSpring({ scroll: fixtureScroll });

  // const {data, loading, error} = useQuery(HomeQuery, {
  //     variables: {
  //         time: now.current.toISOString()
  //     }
  // });

  const { data: landingData, loading: landingLoading } = useQuery(
    HOME_LANDING_QUERY,
    {
      variables: {
        time: now.current.toISOString(),
      },
    }
  );

  let [getMatches, { data: matchesData }] = useLazyQuery(HOME_MATCHES_QUERY, {
    variables: {
      time: now.current.toISOString(),
    },
  });

  if (matchesData) {
    const newMatchesData = { matches: [] };
    if (matchesData.upcomingMatch.length > 0) {
      newMatchesData.matches = [...matchesData.upcomingMatch];
    }
    if (
      matchesData.previousMatchesUpcoming.length > 0 ||
      matchesData.previousMatchesOver.length > 0
    ) {
      newMatchesData.matches = [
        ...newMatchesData.matches,
        ...matchesData.previousMatchesUpcoming,
        ...matchesData.previousMatchesOver,
      ];
    }
    matchesData = newMatchesData;
  }

  const [getPlayers, { data: playersData }] = useLazyQuery(HOME_PLAYERS_QUERY);

  const [getStandings, { data: standingsData }] =
    useLazyQuery(HOME_STANDINGS_QUERY);

  const [getMerch, { data: merchData }] = useLazyQuery(HOME_MERCH_QUERY);

  const [getPantherPack, { data: pantherPackData }] = useLazyQuery(
    HOME_PANTHERPACK_QUERY,
    { fetchPolicy: "no-cache" }
  );

  const [getNews, { data: newsData }] = useLazyQuery(HOME_NEWS_QUERY);

  const [getSponsors, { data: sponsorsData }] =
    useLazyQuery(HOME_SPONSORS_QUERY);

  useEffect(() => {
    if (squadInner.current) {
      setSquadImagePos(squadInner.current.getBoundingClientRect().left);
    }
  }, [screenWidth, playersData]);

  useEffect(() => {
    const setSize = (event) => {
      setScreenWidth(event.target.innerWidth);
    };
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", setSize);
    return () => {
      window.removeEventListener("resize", setSize);
    };
  }, []);

  useEffect(() => {
    clearTimeout(filterChangeTimer.current);
    if (playersData && playersData.players && squadFilter) {
      setSquadArray([]);
      filterChangeTimer.current = setTimeout(() => {
        setSquadArray(
          playersData.players.filter((item) => item.category === squadFilter)
        );
        setSquadPlayerPos(0);
      }, 300);
    }
    return () => clearTimeout(filterChangeTimer.current);
  }, [playersData, squadFilter]);

  useEffect(() => {
    if (playersData && playersData.players) {
      setSquadFilter("RAIDER");
    }
  }, [playersData]);

  useEffect(() => {
    if (standingsData) {
      let newStandings;

      if (
        standingsData.tournaments.length &&
        standingsData.tournaments[0].teamStandings.length
      ) {
        newStandings = [...standingsData.tournaments[0].teamStandings];
      } else if (
        standingsData.tournaments.length &&
        standingsData.tournaments[0].subDivision.length
      ) {
        newStandings = [
          ...standingsData.tournaments[0].subDivision.filter((x) => {
            return x.teamStandings.filter((y) => y.team.isPanther).length > 0;
          })[0].teamStandings,
        ];
      }

      newStandings.sort((a, b) => a.position - b.position);
      if (newStandings.findIndex((standing) => standing.team.isPanther) < 4) {
        setStandings(newStandings.slice(0, 4));
      } else {
        setStandings(
          newStandings.filter((standing, index) => {
            return index < 4 || standing.team.isPanther;
          })
        );
      }
    }
  }, [standingsData]);

  useEffect(() => {
    if (
      pantherPackData &&
      pantherPackData.imageGalleries &&
      pantherPackData.pantherPackVideos
    ) {
      const imageGalleries = [...pantherPackData.imageGalleries].map((item) => {
        const newItem = { ...item };
        newItem.type = "image";
        return newItem;
      });
      const videoGalleries = [...pantherPackData.pantherPackVideos].map(
        (item) => {
          const newItem = { ...item };
          newItem.type = "video";
          return newItem;
        }
      );
      const mediaGalleries = imageGalleries.concat(videoGalleries);
      mediaGalleries.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setMediaGalleries(mediaGalleries);
    }
  }, [pantherPackData]);

  // useEffect(() => {
  //     if(data && data.players){
  //         setSquadFilter('ALLROUNDER');

  //         let newStandings;

  //         if(data.tournaments.length && data.tournaments[0].teamStandings.length){
  //             newStandings = [...data.tournaments[0].teamStandings];
  //         } else if(data.tournaments.length && data.tournaments[0].subDivision.length){
  //             newStandings = [...data.tournaments[0].subDivision.filter(x => {
  //                 return (x.teamStandings.filter(y => y.team.isPanther).length > 0)
  //             })[0].teamStandings];
  //         }

  //         newStandings.sort((a, b) => a.position - b.position);
  //         if(newStandings.findIndex(standing => standing.team.isPanther) < 4){
  //             setStandings(newStandings.slice(0, 4));
  //         }else {
  //             setStandings(newStandings.filter((standing, index) => {
  //                 return (index < 4 || standing.team.isPanther)
  //             }));
  //         }
  //     }
  //     if(data && data.imageGalleries && data.pantherPackVideos){
  //         const imageGalleries = [...data.imageGalleries].map(item => {
  //             const newItem = {...item};
  //             newItem.type = 'image';
  //             return newItem
  //         });
  //         const videoGalleries = [...data.pantherPackVideos].map(item => {
  //             const newItem = {...item};
  //             newItem.type = 'video';
  //             return newItem
  //         });
  //         const mediaGalleries = imageGalleries.concat(videoGalleries);
  //         mediaGalleries.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at)));
  //         setMediaGalleries(mediaGalleries);
  //     }

  //     if(data){
  //         setProgress(prog => prog + 60)
  //     }
  // }, [data]);

  useEffect(() => {
    if (landingData) {
      setProgress((prog) => prog + 20);
      getMatches();
      getPlayers();
      getStandings();
      if (landingData.merchPage.isActive) {
        getMerch();
      }
      getPantherPack();
      getNews();
      getSponsors();
    }
  }, [landingData]);

  const pantherPackScrollForward = () => {
    const remValue = parseFloat(
      window
        .getComputedStyle(document.getElementsByTagName("html")[0])
        .getPropertyValue("font-size")
    );
    const innerWidth = pantherPackRef.current.scrollWidth;
    const visibleWidth = pantherPackRef.current.offsetWidth;
    if (pantherPackRef.current) {
      const pantherPackItemWidth = 23 * remValue;
      const margin = 0.5 * remValue;
      if (
        pantherPackScroll + visibleWidth + pantherPackItemWidth + margin >
        innerWidth
      ) {
        setPantherPackScroll(innerWidth - visibleWidth);
      } else
        setPantherPackScroll(
          (currScroll) => currScroll + pantherPackItemWidth + margin
        );
    }
  };

  const pantherPackScrollBack = () => {
    const remValue = parseFloat(
      window
        .getComputedStyle(document.getElementsByTagName("html")[0])
        .getPropertyValue("font-size")
    );
    if (pantherPackRef.current) {
      const pantherPackItemWidth = 23 * remValue;
      const margin = 0.5 * remValue;
      if (pantherPackScroll - pantherPackItemWidth - margin < 0) {
        setPantherPackScroll(0);
      } else
        setPantherPackScroll(
          (currScroll) => currScroll - pantherPackItemWidth - margin
        );
    }
  };

  const shoppingScrollForward = () => {
    const remValue = parseFloat(
      window
        .getComputedStyle(document.getElementsByTagName("html")[0])
        .getPropertyValue("font-size")
    );
    const innerWidth = shoppingRef.current.scrollWidth;
    const visibleWidth = shoppingRef.current.offsetWidth;
    if (shoppingRef.current) {
      const shoppingItemWidth = 20 * remValue;
      const margin = 0.5 * remValue;
      if (
        shoppingScroll + visibleWidth + shoppingItemWidth + margin >
        innerWidth
      ) {
        setShoppingScroll(innerWidth - visibleWidth);
      } else
        setShoppingScroll(
          (currScroll) => currScroll + shoppingItemWidth + margin
        );
    }
  };

  const shoppingScrollBack = () => {
    const remValue = parseFloat(
      window
        .getComputedStyle(document.getElementsByTagName("html")[0])
        .getPropertyValue("font-size")
    );
    if (shoppingRef.current) {
      const shoppingItemWidth = 20 * remValue;
      const margin = 0.5 * remValue;
      if (shoppingScroll - shoppingItemWidth - margin < 0) {
        setShoppingScroll(0);
      } else
        setShoppingScroll(
          (currScroll) => currScroll - shoppingItemWidth - margin
        );
    }
  };

  const fixtureScrollForward = () => {
    const numberShown = 3;
    const innerWidth = fixtureRef.current.scrollWidth;
    const visibleWidth = fixtureRef.current.offsetWidth;
    if (fixtureRef.current) {
      const fixtureItemWidth = visibleWidth / (numberShown + 0.3);
      const margin =
        visibleWidth * (0.3 / ((numberShown + 0.3) * (numberShown - 1)));
      if (
        fixtureScroll + visibleWidth + fixtureItemWidth + margin >
        innerWidth
      ) {
        setFixtureScroll(innerWidth - visibleWidth);
      } else
        setFixtureScroll(
          (currScroll) => currScroll + fixtureItemWidth + margin
        );
    }
  };

  const fixtureScrollBack = () => {
    const numberShown = 3;
    const visibleWidth = fixtureRef.current.offsetWidth;
    if (fixtureRef.current) {
      const fixtureItemWidth = visibleWidth / (numberShown + 0.3);
      const margin =
        visibleWidth * (0.3 / ((numberShown + 0.3) * (numberShown - 1)));
      if (fixtureScroll - fixtureItemWidth - margin < 0) {
        setFixtureScroll(0);
      } else
        setFixtureScroll(
          (currScroll) => currScroll - fixtureItemWidth - margin
        );
    }
  };

  const detectUrlType = (string) => {
    const images = ["jpg", "gif", "png"];
    const videos = ["mp4", "3gp", "ogg"];

    const url = new URL(CONSTANTS.createUrl(string));
    const extension = url.pathname.split(".").slice(-1)[0];

    if (images.includes(extension)) {
      return "image";
    } else if (videos.includes(extension)) {
      return "video";
    }
  };

  return (
    <>
      <div className="home">
        <div className="section-1">
          {landingData ? (
            <>
              <HomeLandingCarousel
                wideData={landingData.homePage.landingImageWide}
                tallData={landingData.homePage.landingImageTall}
              />
              {/* <img 
                                className="desktop"
                                src={CONSTANTS.createUrl(landingData.homePage.landingImageWide[0].url)}
                                alt={landingData.homePage.landingImageWide[0].alternativeText}
                            />
                            <img 
                                className="mobile"
                                src={CONSTANTS.createUrl(landingData.homePage.landingImageTall[0].url)}
                                alt={landingData.homePage.landingImageTall[0].alternativeText}
                            /> */}
            </>
          ) : null}
          <div className="bottom-row">
            {landingData ? (
              <div className="last-match">
                <div className="title">
                  <p className="title">LAST MATCH</p>
                  <p>{`${landingData.lastMatch[0].tournament.name}, ${format(
                    Date.parse(landingData.lastMatch[0].startTime),
                    "dd/MM -  HH:mm"
                  )}`}</p>
                </div>
                <div className="main">
                  <div className="section-1">
                    <img src={Panther} alt="" />
                    <p className="score">{`${landingData.lastMatch[0].jppScore}-${landingData.lastMatch[0].opponentScore}`}</p>
                    <img
                      src={CONSTANTS.createUrl(
                        landingData.lastMatch[0].team.icon.url
                      )}
                      alt={landingData.lastMatch[0].team.icon.alternativeText}
                    />
                  </div>
                  <div className="section-2">
                    <Link
                      className="item"
                      to={`/matches/${landingData.lastMatch[0].id}`}
                    >
                      <img src={Hub} alt="" />
                      <p>MATCH HUB</p>
                    </Link>
                    {landingData.lastMatch[0].streamUrl ? (
                      <a
                        className="item"
                        href={landingData.lastMatch[0].streamUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Highlights} alt="" />
                        <p>HIGHLIGHTS</p>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <p className={landingData ? "hashtag" : ""}>#ROARFORPANTHERS</p>
          </div>
        </div>
        {matchesData && matchesData.matches.length ? (
          <div className="section-2">
            <div className="title">
              <div className="main">
                <h2>FIXTURES & RESULTS</h2>
                <div className="buttons">
                  <button
                    onClick={() => fixtureScrollBack()}
                    disabled={fixtureScroll === 0}
                  >
                    <img src={Arrow} alt="" />
                  </button>
                  <button
                    onClick={() => fixtureScrollForward()}
                    disabled={
                      fixtureRef.current &&
                      fixtureScroll + fixtureRef.current.offsetWidth ===
                      fixtureRef.current.scrollWidth
                    }
                  >
                    <img src={Arrow} alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="main">
              <a.div
                className="carousel"
                ref={fixtureRef}
                scrollLeft={fixtureStyles.scroll}
              >
                <FixtureAndResults data={singleUpcommingFixture} screenWidth={screenWidth} />
                {matchesData.matches.map((item) => (
                  <FixtureAndResults data={item} screenWidth={screenWidth} />
                ))}

                {/* {matchesData.matches
                  .slice()
                  .reverse()
                  .map((item) => (
                    <FixtureAndResults data={item} screenWidth={screenWidth} />
                  ))} */}
              </a.div>
            </div>
          </div>
        ) : null}
        {playersData ? (
          <div className="section-3">
            <div className="title">
              <p className="hashtag">
                #PANTHER<span>SQUAD</span>
              </p>
              <div className="buttons">
                <button
                  className={squadFilter === "RAIDER" ? "active" : ""}
                  onClick={() => setSquadFilter("RAIDER")}
                >
                  RAIDERS
                </button>
                <button
                  className={squadFilter === "DEFENDER" ? "active" : ""}
                  onClick={() => setSquadFilter("DEFENDER")}
                >
                  DEFENDERS
                </button>
                {/* <button
                  className={squadFilter === "ALLROUNDER" ? "active" : ""}
                  onClick={() => setSquadFilter("ALLROUNDER")}
                >
                  ALL-ROUNDERS
                </button> */}
              </div>
            </div>
            <div className="main">
              <div className="images">
                <button
                  disabled={squadPlayerPos === 0}
                  onClick={() =>
                    setSquadPlayerPos((pos) => {
                      return pos - 1;
                    })
                  }
                >
                  <img src={Arrow} alt="" />
                </button>
                <HomePlayerImages
                  squadPlayerPos={squadPlayerPos}
                  squadImagePos={squadImagePos}
                  screenWidth={screenWidth}
                  setProgress={setProgress}
                  // players={data.players.filter(item => item.category === squadFilter)}
                  players={squadArray}
                />
                <button
                  disabled={squadPlayerPos === squadArray.length - 1}
                  onClick={() =>
                    setSquadPlayerPos((pos) => {
                      return pos + 1;
                    })
                  }
                >
                  <img src={Arrow} alt="" />
                </button>
              </div>
              <div className="inner">
                <button
                  disabled={squadPlayerPos === 0}
                  onClick={() =>
                    setSquadPlayerPos((pos) => {
                      return pos - 1;
                    })
                  }
                >
                  <img src={Arrow} alt="" />
                </button>
                <div
                  className="desktop-content"
                  ref={screenWidth > 500 ? squadInner : null}
                >
                  {squadArray[squadPlayerPos] ? (
                    <>
                      <div className="title">
                        <p className="number">
                          {squadArray[squadPlayerPos].squadNumber}
                        </p>
                        <p className="name">
                          {squadArray[squadPlayerPos].firstName.toUpperCase() +
                            "\n" +
                            squadArray[squadPlayerPos].lastName.toUpperCase()}
                        </p>
                      </div>
                      {squadArray[squadPlayerPos].category === "ALLROUNDER" ? (
                        <p className="position">ALL ROUNDER</p>
                      ) : null}
                      {squadArray[squadPlayerPos].category === "DEFENDER" ? (
                        <>
                          {squadArray[squadPlayerPos].position ===
                            "LEFTCOVER" ? (
                            <p className="position">LEFT COVER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].position ===
                            "LEFTCORNER" ? (
                            <p className="position">LEFT CORNER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].position ===
                            "RIGHTCOVER" ? (
                            <p className="position">RIGHT COVER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].position ===
                            "RIGHTCORNER" ? (
                            <p className="position">RIGHT CORNER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].position ===
                            "DEFENDER" ? (
                            <p className="position">DEFENDER</p>
                          ) : null}
                        </>
                      ) : null}
                      {squadArray[squadPlayerPos].category === "RAIDER" ? (
                        <>
                          {squadArray[squadPlayerPos].position ===
                            "LEFTRAIDER" ? (
                            <p className="position">LEFT RAIDER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].position ===
                            "RIGHTRAIDER" ? (
                            <p className="position">RIGHT RAIDER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].position === "RAIDER" ? (
                            <p className="position">RAIDER</p>
                          ) : null}
                        </>
                      ) : null}
                      <div className="stats">
                        <div className="item">
                          <p className="value">
                            {squadArray[squadPlayerPos].matchesPlayed}
                          </p>
                          <p className="name">MATCHES</p>
                        </div>
                        {squadArray[squadPlayerPos].category ===
                          "ALLROUNDER" ? (
                          <>
                            <div className="item">
                              <p className="value">
                                {squadArray[squadPlayerPos].totalRaidPoints}
                              </p>
                              <p className="name">RAID POINTS</p>
                            </div>
                            <div className="item">
                              <p className="value">
                                {squadArray[squadPlayerPos].totalTacklePoints}
                              </p>
                              <p className="name">TACKLE POINTS</p>
                            </div>
                          </>
                        ) : null}
                        {squadArray[squadPlayerPos].category === "RAIDER" ? (
                          <>
                            <div className="item">
                              <p className="value">
                                {squadArray[squadPlayerPos].totalRaidPoints}
                              </p>
                              <p className="name">RAID POINTS</p>
                            </div>
                            <div className="item">
                              <p className="value">
                                {squadArray[squadPlayerPos].superTens}
                              </p>
                              <p className="name">SUPER 10S</p>
                            </div>
                          </>
                        ) : null}
                        {squadArray[squadPlayerPos].category === "DEFENDER" ? (
                          <>
                            <div className="item">
                              <p className="value">
                                {squadArray[squadPlayerPos].totalTacklePoints}
                              </p>
                              <p className="name">TACKLE POINTS</p>
                            </div>
                            <div className="item">
                              <p className="value">
                                {squadArray[squadPlayerPos].highFives}
                              </p>
                              <p className="name">HIGH 5S</p>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="socials">
                        {squadArray[squadPlayerPos].instagram ? (
                          <a
                            href={squadArray[squadPlayerPos].instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Instagram} alt="" />
                          </a>
                        ) : null}
                        {squadArray[squadPlayerPos].facebook ? (
                          <a
                            href={squadArray[squadPlayerPos].facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Facebook} alt="" />
                          </a>
                        ) : null}
                        {squadArray[squadPlayerPos].twitter ? (
                          <a
                            href={squadArray[squadPlayerPos].twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Twitter} alt="" />
                          </a>
                        ) : null}
                      </div>
                      <div className="buttons">
                        <Link
                          to={`/squad/player/${squadArray[squadPlayerPos].id}`}
                          className="stats"
                        >
                          VIEW STATS
                        </Link>
                        <a
                          href={squadArray[squadPlayerPos].merchLink}
                          className={`get-jersey ${squadArray[squadPlayerPos].merchLink
                            ? ""
                            : "invisible"
                            }`}
                        >
                          GET JERSEY
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
                <div
                  className="mobile-content"
                  ref={screenWidth > 500 ? null : squadInner}
                >
                  {squadArray[squadPlayerPos] ? (
                    <>
                      <div className="title">
                        <p className="number">
                          {squadArray[squadPlayerPos].squadNumber}
                        </p>
                        <div className="info">
                          <p className="name">{`${squadArray[squadPlayerPos].firstName} ${squadArray[squadPlayerPos].lastName}`}</p>
                          {squadArray[squadPlayerPos].category ===
                            "ALLROUNDER" ? (
                            <p className="position">ALL ROUNDER</p>
                          ) : null}
                          {squadArray[squadPlayerPos].category ===
                            "DEFENDER" ? (
                            <>
                              {squadArray[squadPlayerPos].position ===
                                "LEFTCOVER" ? (
                                <p className="position">LEFT COVER</p>
                              ) : null}
                              {squadArray[squadPlayerPos].position ===
                                "LEFTCORNER" ? (
                                <p className="position">LEFT CORNER</p>
                              ) : null}
                              {squadArray[squadPlayerPos].position ===
                                "RIGHTCOVER" ? (
                                <p className="position">RIGHT COVER</p>
                              ) : null}
                              {squadArray[squadPlayerPos].position ===
                                "RIGHTCORNER" ? (
                                <p className="position">RIGHT CORNER</p>
                              ) : null}
                            </>
                          ) : null}
                          {squadArray[squadPlayerPos].category === "RAIDER" ? (
                            <p className="position">RAIDER</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="stats">
                        <div className="item">
                          <p className="value">
                            {squadArray[squadPlayerPos].matchesPlayed}
                          </p>
                          <p className="name">MATCHES</p>
                        </div>
                        <div className="item">
                          <p className="value">
                            {squadArray[squadPlayerPos].totalRaidPoints}
                          </p>
                          <p className="name">RAID POINTS</p>
                        </div>
                        <div className="item">
                          <p className="value">
                            {squadArray[squadPlayerPos].totalTacklePoints}
                          </p>
                          <p className="name">TACKLE POINTS</p>
                        </div>
                      </div>
                      <div className="socials">
                        <Link
                          to={`/squad/player/${squadArray[squadPlayerPos].id}`}
                          className="stats"
                        >
                          <img src={Stats} alt="" />
                        </Link>
                        {squadArray[squadPlayerPos].merchLink ? (
                          <a
                            href={squadArray[squadPlayerPos].merchLink}
                            className="get-jersey"
                          >
                            <img src={Shirt} alt="" />
                          </a>
                        ) : null}
                        {squadArray[squadPlayerPos].instagram ? (
                          <a
                            href={squadArray[squadPlayerPos].instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Instagram} alt="" />
                          </a>
                        ) : null}
                        {squadArray[squadPlayerPos].facebook ? (
                          <a
                            href={squadArray[squadPlayerPos].facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Facebook} alt="" />
                          </a>
                        ) : null}
                        {squadArray[squadPlayerPos].twitter ? (
                          <a
                            href={squadArray[squadPlayerPos].twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Twitter} alt="" />
                          </a>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
                <button
                  disabled={squadPlayerPos === squadArray.length - 1}
                  onClick={() =>
                    setSquadPlayerPos((pos) => {
                      return pos + 1;
                    })
                  }
                >
                  <img src={Arrow} alt="" />
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {standings.length ? (
          <div className="section-4">
            <div className="title">
              <div className="main">
                <h2>POINTS TABLE</h2>
                <Link to={`/standings/${standingsData.tournaments[0].id}`}>
                  COMPLETE TABLE
                </Link>
              </div>
            </div>
            <div className="main">
              <div className="table">
                <div className="title">
                  <div className="item team" />
                  <div className="item position">
                    <img src={Podium} alt="" />
                    <p>POSITION</p>
                  </div>
                  <div className="item wins">
                    <img src={Wins} alt="" />
                    <p>WINS</p>
                  </div>
                  <div className="item losses">
                    <img src={Losses} alt="" />
                    <p>LOSSES</p>
                  </div>
                  <div className="item draws">
                    <img src={Draws} alt="" />
                    <p>DRAWS</p>
                  </div>
                  <div className="item points">
                    <img src={Points} alt="" />
                    <p>POINTS</p>
                  </div>
                </div>
                {standings.map((item) => {
                  if (item.team.isPanther) {
                    return (
                      <div
                        className={`row highlighted ${item.hasQualified ? "qualified" : ""
                          } ${item.team.id ===
                            (standingsData.tournaments[0].winner &&
                              standingsData.tournaments[0].winner.length > 0 &&
                              standingsData.tournaments[0].winner[0].id)
                            ? "winner"
                            : ""
                          }`}
                      >
                        {item.team.id ===
                          (standingsData.tournaments[0].winner &&
                            standingsData.tournaments[0].winner.length > 0 &&
                            standingsData.tournaments[0].winner[0].id) ? (
                          <Trophy />
                        ) : null}
                        <div className="item team">
                          <img src={Panther} alt="pink panther logo" />
                        </div>
                        <div className="item position">
                          {item.position.toString().padStart(2, "0")}
                        </div>
                        <div className="item wins">
                          {item.wins.toString().padStart(2, "0")}
                        </div>
                        <div className="item losses">
                          {item.losses.toString().padStart(2, "0")}
                        </div>
                        <div className="item draws">
                          {item.draws.toString().padStart(2, "0")}
                        </div>
                        <div className="item points">
                          {item.points.toString().padStart(2, "0")}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={`row ${item.hasQualified ? "qualified" : ""
                          } ${item.team.id ===
                            (standingsData.tournaments[0].winner &&
                              standingsData.tournaments[0].winner.length > 0 &&
                              standingsData.tournaments[0].winner[0].id)
                            ? "winner"
                            : ""
                          }`}
                      >
                        {item.team.id ===
                          (standingsData.tournaments[0].winner &&
                            standingsData.tournaments[0].winner.length > 0 &&
                            standingsData.tournaments[0].winner[0].id) ? (
                          <Trophy />
                        ) : null}
                        <div className="item team">
                          <img
                            src={CONSTANTS.createUrl(item.team.icon.url)}
                            alt={item.team.icon.alternativetext}
                          />
                        </div>
                        <div className="item position">
                          {item.position.toString().padStart(2, "0")}
                        </div>
                        <div className="item wins">
                          {item.wins.toString().padStart(2, "0")}
                        </div>
                        <div className="item losses">
                          {item.losses.toString().padStart(2, "0")}
                        </div>
                        <div className="item draws">
                          {item.draws.toString().padStart(2, "0")}
                        </div>
                        <div className="item points">
                          {item.points.toString().padStart(2, "0")}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <Link to={`/standings/${standingsData.tournaments[0].id}`}>
                COMPLETE TABLE
              </Link>
            </div>
          </div>
        ) : null}
        <div className="section-5">
          <div className="media">
            {landingData ? (
              detectUrlType(landingData.homePage.panthersPackMedia.url) ===
                "image" ? (
                <img
                  src={CONSTANTS.createUrl(
                    landingData.homePage.panthersPackMedia.url
                  )}
                  alt={landingData.homePage.panthersPackMedia.alternativeText}
                  onLoad={() => {
                    setProgress((prog) => prog + 20);
                  }}
                />
              ) : (
                <video
                  src={CONSTANTS.createUrl(
                    landingData.homePage.panthersPackMedia.url
                  )}
                  autoPlay={true}
                  preload="auto"
                  muted
                  onCanPlayThrough={() => {
                    setProgress((prog) => prog + 20);
                  }}
                  playsInline
                  loop={true}
                />
              )
            ) : null}
          </div>
          <div className="title">
            <h2>
              PANTHERS <span>PACK</span>
            </h2>
            <div className="line" />
            <p className="small">All the action, exclusive</p>
            {loggedIn && user ? (
              <Link to="/panther-pack">SEE ALL</Link>
            ) : (
              <button
                onClick={() => {
                  if (isSafari) {
                    history.push("/login");
                  } else {
                    setOpen(true);
                  }
                }}
              >
                LOG IN
              </button>
            )}
          </div>
          <a.div
            className="main"
            scrollLeft={pantherPackStyles.scroll}
            ref={pantherPackRef}
          >
            {mediaGalleries.length
              ? mediaGalleries.map((item) => (
                <Link
                  className="item"
                  to={
                    item.type === "image"
                      ? `/panther-pack/image-gallery/${item.id}`
                      : `/panther-pack?video=${item.id}`
                  }
                  onClick={(e) => {
                    if (!(loggedIn && user)) {
                      e.preventDefault();
                      setOpen(true);
                    }
                  }}
                >
                  {item.type === "image" ? (
                    <img
                      src={CONSTANTS.createUrl(item.mainImage.url)}
                      alt={item.mainImage.alternativeText}
                    />
                  ) : (
                    <img
                      src={`https://img.youtube.com/vi/${item.videoUrl.substring(
                        item.videoUrl.indexOf("embed/") + 6
                      )}/0.jpg`}
                      alt=""
                    />
                  )}
                  <div className="row">
                    <p>{item.title}</p>
                    <img src={item.type === "image" ? Camera : Play} alt="" />
                  </div>
                </Link>
              ))
              : null}
          </a.div>
          <div className="controls">
            <div className="line" />
            <div className="buttons">
              <button
                onClick={() => pantherPackScrollBack()}
                disabled={pantherPackScroll === 0}
              >
                <img src={Arrow} alt="" />
              </button>
              <button
                onClick={() => pantherPackScrollForward()}
                disabled={
                  pantherPackRef.current &&
                  pantherPackScroll + pantherPackRef.current.offsetWidth ===
                  pantherPackRef.current.scrollWidth
                }
              >
                <img src={Arrow} alt="" />
              </button>
            </div>
          </div>
        </div>
        {landingData && landingData.merchPage.isActive ? (
          <div className="section-6">
            <div className="title">EXCLUSIVE MERCHANDISE</div>
            <div className="main">
              <div className="text">
                <p className="large">
                  EXCLUSIVE <br /> MERCHANDISE
                </p>
                <p className="small">All the action, exclusive</p>
              </div>
              {merchData && merchData.merchandises.length ? (
                <a.div
                  className="content"
                  scrollLeft={shoppingStyles.scroll}
                  ref={shoppingRef}
                >
                  {merchData.merchandises.map((item) => (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                      className="item"
                    >
                      <img
                        src={CONSTANTS.createUrl(item.image.url)}
                        alt={item.image.alternativeText}
                      />
                    </a>
                  ))}
                </a.div>
              ) : null}
            </div>
            <div className="controls">
              <div className="buttons">
                <button
                  onClick={() => shoppingScrollBack()}
                  disabled={shoppingScroll === 0}
                >
                  <img src={Arrow} alt="" />
                </button>
                <button
                  onClick={() => shoppingScrollForward()}
                  disabled={
                    shoppingRef.current &&
                    shoppingScroll + shoppingRef.current.offsetWidth ===
                    shoppingRef.current.scrollWidth
                  }
                >
                  <img src={Arrow} alt="" />
                </button>
              </div>
              <Link to="/merchandise">SHOP ALL</Link>
            </div>
          </div>
        ) : null}

        {landingData && landingData.homePage.showAppSection ? (
          <div className="section-7">
            <div className="ticker">
              <p>#ROARFORPANTHERS</p>
              <p>#ROARFORPANTHERS</p>
              <p>#ROARFORPANTHERS</p>
              <p>#ROARFORPANTHERS</p>
              <p>#ROARFORPANTHERS</p>
            </div>
            <div className="main">
              <div className="content">
                <p className="large">
                  DOWNLOAD THE <br />
                  OFFICIAL <span>PANTHER</span> APP
                </p>
                <p className="small">Get up to date with club news</p>
                {landingData && landingData.homePage ? (
                  <div className="links">
                    {landingData.homePage.playStoreLink ? (
                      <a
                        href={landingData.homePage.playStoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={GooglePlay} alt="" />
                      </a>
                    ) : null}
                    {landingData.homePage.appStoreLink ? (
                      <a
                        href={landingData.homePage.appStoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Apple} alt="" />
                      </a>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="image">
                <img src={LandingPhone} alt="" />
              </div>
              <div className="mobile-only">
                <p className="small">Get up to date with club news</p>
                <div className="links">
                  {landingData.homePage.playStoreLink ? (
                    <a
                      href={landingData.homePage.playStoreLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={GooglePlay} alt="" />
                    </a>
                  ) : null}
                  {landingData.homePage.appStoreLink ? (
                    <a
                      href={landingData.homePage.appStoreLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={Apple} alt="" />
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {newsData && newsData.newsItems.length ? (
          <div className="section-8">
            {newsData.wideNews ? (
              <div className="row-1">
                <div className="text-item">
                  <div className="extra">
                    <p className="date">
                      {format(
                        new Date(newsData.wideNews[0].date),
                        "dd MMMM yy"
                      )}
                    </p>
                    <p className="tag pink">
                      {newsData.wideNews[0].news_label.name.toUpperCase()}
                    </p>
                  </div>
                  <h3>{newsData.wideNews[0].title}</h3>
                  <p>{newsData.wideNews[0].text}</p>
                  <div className="buttons">
                    {newsData.wideNews[0].articleUrl ? (
                      <a
                        href={newsData.wideNews[0].articleUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Arrow} alt="" />
                      </a>
                    ) : (
                      <Link to={`/news/${newsData.wideNews[0].id}`}>
                        <img src={Arrow} alt="" />
                      </Link>
                    )}
                  </div>
                </div>
                <div className="large-item">
                  <img
                    src={CONSTANTS.createUrl(newsData.wideNews[0].image.url)}
                    alt={newsData.wideNews[0].image.alternativeText}
                  />
                </div>
              </div>
            ) : null}
            <div className="row-2">
              {newsData.newsItems.map((item) => {
                return item.articleUrl ? (
                  <a href={item.articleUrl} className="item">
                    <img
                      src={CONSTANTS.createUrl(item.image.url)}
                      alt={item.image.alternativeText}
                    />
                    <div className="content">
                      <div className="extra">
                        <p className="date">
                          {format(new Date(item.date), "dd MMMM yy")}
                        </p>
                        <p className="tag red">
                          {item.news_label.name.toUpperCase()}
                        </p>
                      </div>
                      <p className="large">{item.title}</p>
                    </div>
                  </a>
                ) : (
                  <Link to={`/news/${item.id}`} className="item">
                    <img
                      src={CONSTANTS.createUrl(item.image.url)}
                      alt={item.image.alternativeText}
                    />
                    <div className="content">
                      <div className="extra">
                        <p className="date">
                          {format(new Date(item.date), "dd MMMM yy")}
                        </p>
                        <p className="tag red">
                          {item.news_label.name.toUpperCase()}
                        </p>
                      </div>
                      <p className="large">{item.title}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : null}

        <div className="section-9 desktop">
          {sponsorsData && sponsorsData.sponsorTypes
            ? sponsorsData.sponsorTypes.map((item) => (
              <SponsorRow data={item} />
            ))
            : null}
        </div>
        <div className="section-9 mobile">
          {sponsorsData && sponsorsData.sponsorTypes
            ? sponsorsData.sponsorTypes.map((item) => (
              <SponsorRow data={item} />
            ))
            : null}
        </div>
        {/* <iframe 
                    src="https://widget.taggbox.com/65209" 
                    style={{
                        overflow: 'auto', 
                        border: '3px solid var(--secondaryColor)'
                    }}
                    title="taggbox"
                    className="taggbox"
                >
                    
                </iframe> */}
      </div>
      {!landingData ? <PageTransition /> : null}
    </>
  );
}
