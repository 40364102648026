import {useState, useEffect, useRef} from 'react';
import './Header.scss';
import Logo from '../../Assets/pink-panther-logo.png';
import {ReactComponent as Hamburger} from '../../Assets/Icons/hamburger.svg';
import {ReactComponent as Profile} from '../../Assets/Icons/profile.svg';
import Ambuja from '../../Assets/Sponsors/ambuja.png';
import {NavLink, Link, useLocation, useHistory} from 'react-router-dom';
import User from '../../Assets/login-image.png';
import CONSTANTS from '../../Constants';

export default function Header({setLoginOpen, setPageNumber, loggedIn, user, logout, setSidenavOpen, ticketsPage, merchPage, titleSponsor, isSafari}){
    const [atTop, setAtTop] = useState(true); // boolean denoting whether viewport is at the top of the screen
    const [scrollDir, setScrollDir] = useState(0); // +ve when scrolling down, 0 when scrolling up
    const [showProfile, setShowProfile] = useState(false);
    
    const location = useLocation();
    const history = useHistory();
    const profileButton = useRef();

    // Adds an event listener for all header animations
    useEffect(() => {
        setAtTop((window.scrollY === 0));
        const remValue = parseFloat(window.getComputedStyle(document.getElementsByTagName('html')[0]).getPropertyValue('font-size'));
        let position = 0;
        let previousPosiion = 0;
        let threshold = 5 * remValue;
        let ticking = false;
        
        // Sets atTop to true when window.scrollY is under 20px
        const scrollTopHandler = (position) => {            
            if(position < threshold){
                setAtTop(true);
            }else if(position >= threshold){
                setAtTop(false);
            }
        };

        // Sets scrollDir to positive value if scrolling down
        const scrollDifferenceHandler = (previousPos, currentPos) => {
            if(previousPos < currentPos){
                setScrollDir(1);
            } else setScrollDir(0)
        }

        // Throttled callback for scroll event listener
        const callback = (event) => {
            previousPosiion = position;
            position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function() {
                    scrollTopHandler(position);
                    scrollDifferenceHandler(previousPosiion, position);
                    ticking = false;
                });

                ticking = true;
            }
        } 

        document.addEventListener('scroll', callback);

        return(() => document.removeEventListener('scroll', callback));
    },[]);

    useEffect(() => {
        if (atTop){
            setShowProfile(false);
        }
        
    }, [atTop]);

    return(
        <div className={`header${((location.pathname ==="/home") && atTop)? ' transparent' : ''}${(scrollDir > 0)&&!atTop? ' invisible' : ''}`}>
            <Link to="/home" className="logo">
                <img src={Logo} alt="" />
            </Link>
            <div className="inner-links">
                <NavLink activeClassName={"active"} to="/squad">SQUAD</NavLink>
                <NavLink activeClassName={"active"} to="/matches">MATCHES</NavLink>
                <NavLink activeClassName={"active"} to="/standings">STANDINGS</NavLink>
                {(merchPage)?<NavLink activeClassName={"active"} to="/merchandise">MERCHANDISE</NavLink> : null}
                <NavLink activeClassName={"active"} to="/news">NEWS</NavLink>
                {(ticketsPage)?<NavLink activeClassName={"active"} to="/tickets">TICKETS</NavLink> : null}
                <NavLink activeClassName={"active"} to="/panther-pack">PANTHERS PACK</NavLink>
            </div>
            {titleSponsor? 
            <a 
                className="sponsor"
                href={titleSponsor.url}
                target="_blank"
                rel="noreferrer"
            >
                <p>title sponsor</p>
                <img src={CONSTANTS.createUrl(titleSponsor.image.url)} alt="" />
            </a>:null}
            <button 
                onClick={(e) => {
                    if(loggedIn && user){
                        if(showProfile){
                            if((e.target === profileButton.current) || profileButton.current.contains(e.target)){
                                return;
                            } else{
                                setShowProfile(false);
                            }
                        } else{
                            setShowProfile(true);
                        }
                    }else{
                        if(isSafari){
                            history.push("/login");
                        } else{
                            setLoginOpen(true)
                            setPageNumber(1);
                        }
                    }
                }}
                onBlur={() => setShowProfile(false)}
            >
                {(loggedIn&&user)? 
                    <img src={`https://ui-avatars.com/api/?name=${user.username}&background=random`} alt="" />
                    : <Profile /> 
                }    
                {(loggedIn&&user)? 
                    <div 
                        className={`profile-hover ${showProfile?'open':''}`}
                        // tabIndex="-1"
                        ref={profileButton}
                    >
                        <img src={`https://ui-avatars.com/api/?name=${user.username}&background=random`} alt="" />
                        <p>{user.username}</p>
                        <button
                            onClick={(e) => {
                                setShowProfile(false);
                                setPageNumber(2);
                                setLoginOpen(true);
                            }}
                        >EDIT PROFILE</button>
                        <button
                            onClick={() => {
                                logout();
                            }}
                        >SIGN OUT</button>
                    </div>
                :null}
            </button>
            <button 
                className="sidenav"
                onClick={() => {
                    setSidenavOpen(true)
                }}
            >
                <Hamburger /> 
            </button>
        </div>
    )
}