import { useState, useEffect, useRef } from "react";
import './SponsorRow.scss';
import Arrow from '../../Assets/Icons/dropdown-arrow.svg';
import { useTransition, a } from "react-spring";
import CONSTANTS from "../../Constants";

export default function SponsorRow({ data }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(null);
    // const [animate, setAnimate] = useState(true);

    const animate = useRef(true);


    const transitions = useTransition(currentIndex, {
        initial: { left: '0%' },
        from: (direction > 0) ? { left: '100%' } : { left: '-100%' },
        enter: { left: '0%' },
        //leave: (direction > 0)? {left: '-100%'} : {left: '100%'},
        onRest: () => {
            animate.current = true
        }
    });

    const getItems = (currentIndex) => {
        if (currentIndex > (data.sponsors.length - 3)) {
            const forwardItems = data.sponsors.slice(currentIndex).concat(data.sponsors.slice(0, 3 - (data.sponsors.length - (currentIndex))));
            return forwardItems;
        } else {
            const forwardItems = data.sponsors.slice(currentIndex, currentIndex + 3);
            return forwardItems;
        }
    }

    const goForwards = () => {
        if (animate.current) {
            animate.current = false;
            setDirection(1);
            setCurrentIndex(index => {
                if (index < data.sponsors.length - 3) {
                    return index + 3
                } else return (3 - (data.sponsors.length - index));
            })
        }
    };

    const goBack = () => {
        if (animate.current) {
            animate.current = false;
            setDirection(0);
            setCurrentIndex(index => {
                if (index > 2) {
                    return index - 3
                } else return (data.sponsors.length - (3 - index))
            })
        }
    };

    useEffect(() => {
        var timer;
        if (data.sponsors.length > 3) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                goForwards();
            }, 2000);
        }
        return () => {
            clearTimeout(timer);
        }
    }, [currentIndex]);


    return (
        <div className="sponsor-row">
            {data.name ? <p className={`title`}>{data.name}</p> : null}
            <div className="main">
                <div className="blocker" />
                {(data.sponsors.length > 3) ? <button onClick={goBack}><img src={Arrow} alt="" /></button> : null}
                <div className="scroller">
                    {(data.sponsors.length > 3) ?
                        transitions((styles, index) => {
                            return <a.div className="group" style={styles}>
                                {getItems(index).map(item => {
                                    return <div className="item">
                                        <div className={`subtitle ${(data.type === 'tier1') ? 'large' : ''}`}>
                                            <p>{item.title}</p>
                                        </div>
                                        <a href={item.url} target="_blank" rel="noreferrer">
                                            <img src={CONSTANTS.createUrl(item.image.url)} alt={item.image.alternativeText} />
                                        </a>
                                    </div>
                                })}
                            </a.div>
                        }) :
                        <div className="group">
                            {data.sponsors.map(item =>
                                <div className="item">
                                    <div className={`subtitle ${(data.type === 'tier1') ? 'large' : ''}`}>
                                        <p>{item.title}</p>
                                    </div>
                                    <a href={item.url} target="_blank" rel="noreferrer">
                                        <img src={CONSTANTS.createUrl(item.image.url)} alt={item.image.alternativeText} />
                                    </a>
                                </div>
                            )}
                        </div>
                    }
                </div>
                {(data.sponsors.length > 3) ? <button onClick={goForwards}><img src={Arrow} alt="" /></button> : null}
                <div className="blocker" />
            </div>
        </div>
    )
}