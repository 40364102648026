import { useState, useEffect, useRef } from 'react';
import './MatchStats.scss';
import { useSpring, a, config } from 'react-spring';

export default function MatchStats({data}){
    const [animate, setAnimate] = useState(false); // Starts animations when true

    const element = useRef();

    const {self, opponent, width} = useSpring({
        self: animate? data.self : 0, 
        opponent: animate? data.opponent : 0,
        width: animate? `${((data.opponent + data.self) === 0)? 50 : (100 * data.self/(data.opponent + data.self))}%` : '0%',
        config: config.molasses
    });

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const callback = (entries) => {
            if(!animate && entries[0].isIntersecting){
                setAnimate(true);
            }            
        }
            
        const observer = new IntersectionObserver(callback, options);
        observer.observe(element.current)
    },[]);
    

    return(
        <div className="match-stat-item">
            <p className="title">{data.name}</p>
            {data.type === 'form'?             
                <div className="form" ref={element}>
                    <div className="item">
                        <div className="stats">
                            <div className="item">
                                <p className="label">WON</p>
                                <p className="value">{data.self.filter(x => x === 'win').length}</p>
                            </div>
                            <div className="item">
                                <p className="label">LOSS</p>
                                <p className="value">{data.self.filter(x => x === 'loss').length}</p>
                            </div>
                            <div className="item">
                                <p className="label">DRAW</p>
                                <p className="value">{data.self.filter(x => x === 'draw').length}</p>
                            </div>
                        </div>
                        <div className="indicators">
                            {data.self.map(item => 
                                <div className={`item ${item === 'win'? '' : 'pink'} ${item === 'draw'? 'draw' : ''}`}>
                                    <svg>
                                        <circle cx="50%" cy="50%" r="50%"/>
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="item">
                        <div className="stats">
                            <div className="item">
                                <p className="label">WON</p>
                                <p className="value">{data.opponent.filter(x => x === 'win').length}</p>
                            </div>
                            <div className="item">
                                <p className="label">LOSS</p>
                                <p className="value">{data.opponent.filter(x => x === 'loss').length}</p>
                            </div>
                            <div className="item">
                                <p className="label">DRAW</p>
                                <p className="value">{data.opponent.filter(x => x === 'draw').length}</p>
                            </div>
                        </div>
                        <div className="indicators">
                        {data.opponent.map(item => 
                                <div className={`item ${item === 'win'? '' : 'pink'} ${item === 'draw'? 'draw' : ''}`}>
                                    <svg>
                                        <circle cx="50%" cy="50%" r="50%"/>
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                :<div className="main" ref={element}>
                    <a.p className="value">{self.to(x => x.toFixed(0))}</a.p>
                    <div className="indicator">
                        <a.div style={{width: width}}/>
                    </div>
                    <a.p className="value">{opponent.to(x => x.toFixed(0))}</a.p>
                </div> 
            }
        </div>
    )
}