import { useState, useEffect } from 'react';
import './Tickets.scss';
import { ReactComponent as Cross } from '../../Assets/Icons/cross.svg';
import { Dialog, makeStyles } from '@material-ui/core';
import { ReactComponent as Calendar } from '../../Assets/Icons/calendar.svg';
import { ReactComponent as Stadium } from '../../Assets/Icons/stadium.svg';
import Ticket from '../../Assets/Icons/ticket.svg';
import { useQuery, gql } from '@apollo/client';
import PageTransition from '../../Components/PageTransition/PageTransition';
import CONSTANTS from '../../Constants';
import { format } from 'date-fns';

const TICKETS_QUERY = gql`
query TicketPageQuery{
    ticketsPage{
        miniBanner,
        heroMedia{
            url,
            alternativeText
        },
        ticket{
            match{
                team1{
                    name,
                    icon{
                        url,
                        alternativeText
                    }
                },
                team2{
                    name,
                    icon{
                        url,
                        alternativeText
                    }
                },
                startTime
            },
            venue{
                name
            },
            startTime,
            endTime,
            title,
            url
        }
    }
}`;

const useStyles = makeStyles((theme) => ({
    paperRoot: {
        backgroundColor: 'transparent',
        width: '80%',
        height: '95%'
    },
    backdropRoot: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
    }
}));

export default function Tickets({ setProgress }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [iframeSource, setIframeSource] = useState(null);
    const [bannerVisible, setBannerVisible] = useState(true);
    const { data, error, loading } = useQuery(TICKETS_QUERY);
    console.log(data);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!open) {
            setIframeSource(null);
        }
    }, [open]);

    useEffect(() => {
        if (data) {
            setProgress(prog => prog + 60);
        }
    }, [data]);

    const detectUrlType = (string) => {
        const images = ["jpg", "gif", "png"]
        const videos = ["mp4", "3gp", "ogg"]

        const url = new URL(CONSTANTS.createUrl(string));
        const extension = url.pathname.split(".").slice(-1)[0];

        if (images.includes(extension)) {
            return 'image';
        } else if (videos.includes(extension)) {
            return 'video';
        }
    }
    if (data) {
        return (
            <div className="tickets">
                {(data && data.ticketsPage.miniBanner) ?
                    <p
                        className={`mini-banner ${bannerVisible ? '' : 'invisible'}`}
                    >{data.ticketsPage.miniBanner} <Cross onClick={() => setBannerVisible(false)} /></p>
                    : null
                }
                <div className="section-1">
                    {data ?
                        (
                            (detectUrlType(data.ticketsPage.heroMedia.url) === 'image') ?
                                <img
                                    src={CONSTANTS.createUrl(data.ticketsPage.heroMedia.url)}
                                    alt={data.ticketsPage.heroMedia.alternativeText}
                                />
                                : <video
                                    src={CONSTANTS.createUrl(data.ticketsPage.heroMedia.url)}
                                    autoPlay={true}
                                    preload='auto'
                                    muted
                                    loop={true}
                                />
                        )
                        : null
                    }
                    <p>MATCH TICKETS</p>
                </div>
                <div className="section-2">
                    {data.ticketsPage.ticket.map(item => {
                        var s = item.venue.name;
                        if (s.includes(' ')) {
                            var middle = Math.floor(s.length / 2);
                            var before = s.lastIndexOf(' ', middle);
                            var after = s.indexOf(' ', middle + 1);

                            if (before === -1 || (after !== -1 && middle - before >= after - middle)) {
                                middle = after;
                            } else {
                                middle = before;
                            }

                            var s1 = s.substr(0, middle);
                            var s2 = s.substr(middle + 1);
                        };
                        return <div className="item">
                            <h3>{item.title}</h3>
                            <div className="main">
                                {item.match.map((match, index, arr) =>
                                    <div className={`item ${(arr.length === 1) ? "single" : ""}`}>
                                        <div className="team">
                                            <img src={CONSTANTS.createUrl(match.team1.icon.url)} alt={match.team1.icon.alternativeText} />
                                            <p>{match.team1.name}</p>
                                        </div>
                                        <p className="time">{format(new Date(match.startTime), 'h:mm aa')}</p>
                                        <div className="team">
                                            <img src={CONSTANTS.createUrl(match.team2.icon.url)} alt={match.team2.icon.alternativeText} />
                                            <p>{match.team2.name}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="extra">
                                <div className="time">
                                    <Calendar />
                                    <p>{format(new Date(item.startTime), 'd MMMM y').toUpperCase()} <br />{`${format(new Date(item.startTime), 'h:mm')}-${format(new Date(item.endTime), 'h:mm aa')}`}</p>
                                </div>
                                <button onClick={() => {
                                    setIframeSource(item.url);
                                    window.open(item.url, '_blank');
                                    setOpen(false);
                                }}>
                               {/* <button onClick={() => {
                                    setIframeSource(item.url);
                                    setOpen(true);
                                }}> */}

                                    <img src={Ticket} alt="" />
                                    <p>GET TICKETS</p>
                                </button>
                                <div className="venue">
                                    <Stadium />
                                    <p>{s1}<br />{s2}</p>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    BackdropProps={{ classes: { root: classes.backdropRoot } }}
                    PaperProps={{ classes: { root: classes.paperRoot } }}
                    fullScreen
                >
                    <div className="ticket_iframe_container">
                        {iframeSource ? <iframe
                            //src={'sept-2019/event'} 
                            src={iframeSource}
                            title="Buy tickets"
                        // frameBorder="0" 
                        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                        // allowFullScreen
                        ></iframe> : null}
                    </div>
                </Dialog>
            </div >
        )
    } else return <PageTransition />
}